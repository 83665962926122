import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { InlineEditor } from '@ckeditor/ckeditor5-editor-inline';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Bold, Italic, Subscript, Superscript } from '@ckeditor/ckeditor5-basic-styles';
import { AutoLink, Link, LinkImage } from '@ckeditor/ckeditor5-link';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { Font } from '@ckeditor/ckeditor5-font';
import { GeneralHtmlSupport, HtmlComment } from '@ckeditor/ckeditor5-html-support';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { HtmlEmbed } from '@ckeditor/ckeditor5-html-embed';
import { Image, ImageUpload, ImageCaption, ImageResize, ImageStyle, ImageToolbar, ImageResizeEditing, ImageResizeHandles } from '@ckeditor/ckeditor5-image';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { RemoveFormat } from '@ckeditor/ckeditor5-remove-format';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { SpecialCharacters, SpecialCharactersEssentials, SpecialCharactersCurrency, SpecialCharactersMathematical } from '@ckeditor/ckeditor5-special-characters';
import { SimpleUploadAdapter } from '@ckeditor/ckeditor5-upload';
import { Table, TableToolbar, TableCellProperties, TableProperties } from '@ckeditor/ckeditor5-table';
import { Indent } from '@ckeditor/ckeditor5-indent';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { List } from '@ckeditor/ckeditor5-list';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import icon from "@ckeditor/ckeditor5-ckfinder/theme/icons/browse-files.svg";

export default class InsertFile extends Plugin {
    init() {
        const editor = this.editor;
        editor.editing.view.document.on(
            "drop",
            async (event, data) => {
                if (
                    data.dataTransfer.files &&
                    !data.dataTransfer.files[0].type.includes("image")
                ) {
                    event.stop();
                    data.preventDefault();
                    this.insert(data.dataTransfer.files[0], editor);
                }
            },
            { priority: "high" }
        );

        editor.editing.view.document.on(
            "dragover",
            (event, data) => {
                event.stop();
                data.preventDefault();
            },
            { priority: "high" }
        );

        editor.ui.componentFactory.add("insertFile", (locale) => {
            const inputElement = document.createElement("input");
            inputElement.type = "file";
            inputElement.accept = ".pdf,application/pdf";
            inputElement.addEventListener("change", (event) => {
                this.insert(event.target.files[0], editor);
            });

            const view = new ButtonView(locale);

            view.set({
                label: "Insert file",
                icon: icon,
                tooltip: true,
            });

            view.on("execute", () => {
                inputElement.dispatchEvent(new MouseEvent("click"));
            });

            return view;
        });
    }

    insert(file, editor) {
        const options = editor.config.get('insertFile');
        if (file) {
            const form = new FormData();
            form.append("Content-Type", file.type);
            form.append("upload", file);
            let httpRequest;
            if (window.XMLHttpRequest) {
                httpRequest = new XMLHttpRequest();
            } else if (window.ActiveXObject) {
                httpRequest = new ActiveXObject("Microsoft.XMLHTTP");
            }
            httpRequest.upload.onprogress = (ev) => {
                let progress = undefined;
                progress = (ev.loaded / ev.total) * 100;
                window.status = progress + "%";
            };
            httpRequest.open("POST", options.uploadUrl, true);
            httpRequest.onload = () => {
                if (httpRequest.status === 200) {
                    const body = JSON.parse(httpRequest.responseText);

                    const selection = editor.model.document.selection;
                    const range = selection.getFirstRange();
                    let linkText = file.name;
                    for (const item of range.getItems()) {
                        linkText = item.data;
                    }

                    const content = `<a href="${body.url}" target="_blank"/>${linkText}</a>`;
                    const viewFragment = editor.data.processor.toView(content);
                    const modelFragment = editor.data.toModel(viewFragment);
                    editor.model.insertContent(
                        modelFragment,
                        editor.model.document.selection
                    );

                } else {

                    alert(
                        `Something went wrong while uploading your file, please check your network connection and try again.
          (${httpRequest.status} ${httpRequest.statusText})`
                    );
                    console.error(
                        "Request failed.  Returned status of " + httpRequest.status,
                        httpRequest
                    );
                }
            };
            httpRequest.send(form);
        }
    }
}

ClassicEditor.builtinPlugins = [Essentials, Paragraph, Bold, Italic, Link, Alignment, AutoLink, Font, Subscript, Superscript, Indent,
    Image, ImageUpload, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageResizeEditing, ImageResizeHandles, GeneralHtmlSupport,
    Heading, HorizontalLine, HtmlComment, HtmlEmbed, SimpleUploadAdapter, LinkImage, MediaEmbed, PasteFromOffice, RemoveFormat, SourceEditing, List,
    SpecialCharacters, SpecialCharactersEssentials, SpecialCharactersCurrency, SpecialCharactersMathematical, Table, TableToolbar, TableCellProperties, TableProperties, InsertFile];

InlineEditor.builtinPlugins = [Essentials, Paragraph, Bold, Italic, Link, Alignment, AutoLink, Font, Subscript, Superscript, Indent,
    Image, ImageUpload, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageResizeEditing, ImageResizeHandles, GeneralHtmlSupport,
    Heading, HorizontalLine, HtmlComment, HtmlEmbed, SimpleUploadAdapter, LinkImage, MediaEmbed, PasteFromOffice, RemoveFormat, List,
    SpecialCharacters, SpecialCharactersEssentials, SpecialCharactersCurrency, SpecialCharactersMathematical, Table, TableToolbar, TableCellProperties, TableProperties, InsertFile];

ClassicEditor.defaultConfig = {
    toolbar: ['heading', '|', 'undo', 'redo', 'removeFormat', '|',
        'bold', 'italic', 'fontColor', 'fontBackgroundColor', 'link', 'subscript', 'superscript', '|',
        'alignment', 'bulletedList', 'numberedList', 'outdent', 'indent', '|',
        'specialCharacters', 'horizontalLine', 'imageUpload', 'insertTable', 'mediaEmbed', 'htmlEmbed', '|', 'sourceEditing', 'insertFile'],
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: '/ckeditor/uploadImage',

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: true,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            'X-CKEDITOR': '1'
        }
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
        tableCellProperties: {
            defaultProperties: {
                horizontalAlignment: 'left',
                verticalAlignment: 'bottom',
                padding: '5px'
            }
        }
    },
    image: {
        toolbar: [
            'imageStyle:block',
            'imageStyle:inline',
            'imageStyle:alignLeft',
            'imageStyle:alignRight',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'linkImage'
        ]
    },
    language: 'de-CH'
};

InlineEditor.defaultConfig = {
    toolbar: ['heading', '|', 'undo', 'redo', 'removeFormat', '|',
        'bold', 'italic', 'fontColor', 'fontBackgroundColor', 'link', 'subscript', 'superscript', '|',
        'alignment', 'bulletedList', 'numberedList', 'outdent', 'indent', '|',
        'specialCharacters', 'horizontalLine', 'imageUpload', 'insertTable', 'mediaEmbed', 'htmlEmbed'],
    simpleUpload: {
        // The URL that the images are uploaded to.
        uploadUrl: '/ckeditor/uploadImage',

        // Enable the XMLHttpRequest.withCredentials property.
        withCredentials: true,

        // Headers sent along with the XMLHttpRequest to the upload server.
        headers: {
            'X-CSRF-TOKEN': 'CSRF-Token',
            'X-CKEDITOR': '1'
        }
    },
    table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
        tableCellProperties: {
            defaultProperties: {
                horizontalAlignment: 'left',
                verticalAlignment: 'bottom',
                padding: '5px'
            }
        }
    },
    image: {
        toolbar: [
            'imageStyle:block',
            'imageStyle:side',
            '|',
            'toggleImageCaption',
            'imageTextAlternative',
            '|',
            'linkImage'
        ]
    },
    language: 'de-CH'
};

window.ClassicEditor = ClassicEditor;

window.InlineEditor = InlineEditor;

/*
ClassicEditor
    .create(document.querySelector('#editor'), {
        plugins: [Essentials, Paragraph, Bold, Italic, Link, Alignment, AutoLink, Font, Subscript, Superscript, Indent,
            Image, ImageUpload, ImageToolbar, ImageCaption, ImageStyle, ImageResize, ImageResizeEditing, ImageResizeHandles, GeneralHtmlSupport,
            Heading, HorizontalLine, HtmlComment, HtmlEmbed, SimpleUploadAdapter, LinkImage, MediaEmbed, PasteFromOffice, RemoveFormat, SourceEditing, List,
            SpecialCharacters, SpecialCharactersEssentials, SpecialCharactersCurrency, SpecialCharactersMathematical, Table, TableToolbar, TableCellProperties, TableProperties],
        toolbar: ['heading', '|', 'undo', 'redo', 'removeFormat', '|',
            'bold', 'italic', 'fontColor', 'fontBackgroundColor', 'link', 'subscript', 'superscript', '|',
            'alignment', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 
            'specialCharacters', 'horizontalLine', 'imageUpload', 'insertTable', 'mediaEmbed', 'htmlEmbed', '|', 'sourceEditing'],
        simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: '/xtend/aspx/imageupload.aspx',

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,

            // Headers sent along with the XMLHttpRequest to the upload server.
            headers: {
                'X-CSRF-TOKEN': 'CSRF-Token',
                'X-CKEDITOR': '1'
            }
        },
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties'],
            tableCellProperties: {
                defaultProperties: {
                    horizontalAlignment: 'left',
                    verticalAlignment: 'bottom',
                    padding: '5px'
                }
            }
        },
        image: {
            toolbar: [
                'imageStyle:block',
                'imageStyle:side',
                '|',
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'linkImage'
            ]
        }
    })
    .then(editor => {
        console.log('Editor was initialized', editor);
    })
    .catch(error => {
        console.error(error.stack);
    });
    */
    /*
InlineEditor
    .create(document.querySelector('#inlineeditor'), {
        plugins: [Essentials, Paragraph, Bold, Italic],
        toolbar: ['bold', 'italic']
    })
    .then(editor => {
        console.log('Editor was initialized', editor);
    })
    .catch(error => {
        console.error(error.stack);
    });
    */
